var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useMemo, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import * as tracking from '../../../tracking';
import loadScript from 'load-script';
import { useTrackPage } from '../../../tracking/amplitude/hooks/useTrackPage';
import DirectApi from '../../../directApi';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import { RadioButton } from 'web-components';
import { settingsForNewProjectsAndTasks, settingsForExistingProjectsAndTasks, settingsForNewPhasesTasksAndTimeRegsOnExistingProjects, } from './SettingsUtil';
import { FlexRow, DeprecatedText as Text } from '@forecast-it/design-system';
import TooltipContainer from '../../shared/components/tooltips/tooltip_container';
import DataImportValidator from './DataImportValidator';
import { Team } from '../../../sentry/GetTeamFromPathname';
import ImportMode from './ImportMode';
import { SubmitDataImport } from './SubmitDataImport';
export const DescriptionStyled = styled.div `
	.description {
		padding-left: 38px;
		padding-right: 0px;
		padding-top: 16px;
		padding-bottom: 16px;
	}
	.header {
		display: flex;
		padding-left: 38px;
		padding-right: 0px;
		padding-top: 20px;
		padding-bottom: 0px;
	}
	.header-title {
		display: flex;
		color: #535353;
		font-size: 20px;
		font-weight: 500;
	}
	.sub-description {
		padding-left: 38px;
		padding-right: 0px;
		padding-top: 15px;
	}
	.bold {
		font-weight: bold;
		padding-top: 15px;
		margin: 0px;
	}
	.button {
		display: flex;
		justify-content: flex-start;
		padding-left: 38px;
		margin-top: 20px;
		gap: 20px;
	}
	.my-nuvo-style1 {
		position: relative;
		max-height: 500px;
		max-width: 80vw;
		overflow: scroll;
	}
`;
export const RateCardsStyle = styled.div `
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding-bottom: 10px;
`;
export const Section = styled.div `
	display: flex;
	max-width: 500px;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 8px;
`;
export const SubSection = styled.div `
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 12px;
	padding-left: 8px;
	padding-top: 8px;
`;
const PAGE_NAME = 'Data Importer';
const TopHeader = () => {
    return (React.createElement("div", { className: "header" },
        React.createElement(TooltipContainer, { infoText: [
                {
                    unstyledList: [
                        'Here you can import / migrate data to your account. For details see our guide ',
                        React.createElement("a", { target: "_blank", href: "https://support.forecast.app/hc/en-us" }, "https://support.forecast.app"),
                    ],
                    noBox: true,
                },
            ], tooltipInfinteDuration: true, edgeOffset: 350 },
            React.createElement("a", { className: "header-title" }, "Data Import"))));
};
const ImportSelection = ({ importMode, setImportMode, disabled = false, }) => {
    return (React.createElement(Section, null,
        React.createElement(SubSection, null,
            React.createElement(FlexRow, { gap: 's' },
                React.createElement(RadioButton, { checked: importMode === ImportMode.ImportNewProjects, onClick: () => setImportMode(ImportMode.ImportNewProjects), disabled: disabled }),
                React.createElement(Text, null,
                    "Import",
                    ' ',
                    React.createElement("b", null,
                        React.createElement("u", null, "new")),
                    ' ',
                    "projects, tasks and time entries.")),
            React.createElement(FlexRow, { gap: 's' },
                React.createElement(RadioButton, { checked: importMode === ImportMode.ImportTimeRegs, onClick: () => setImportMode(ImportMode.ImportTimeRegs), disabled: disabled }),
                React.createElement(Text, null,
                    "Import time entries into",
                    ' ',
                    React.createElement("b", null,
                        React.createElement("u", null, "existing")),
                    ' ',
                    "tasks or projects.")),
            React.createElement(FlexRow, { gap: 's' },
                React.createElement(RadioButton, { checked: importMode === ImportMode.ImportPhasesTasksTimeRegs, onClick: () => setImportMode(ImportMode.ImportPhasesTasksTimeRegs), disabled: disabled }),
                React.createElement(Text, null,
                    "Import phases, tasks or time entries into",
                    ' ',
                    React.createElement("b", null,
                        React.createElement("u", null, "existing")),
                    ' ',
                    "projects.")))));
};
const ResultText = ({ success }) => {
    if (success) {
        return (React.createElement("div", { className: "bold" },
            React.createElement("p", null, "Data successfully uploaded and will now be added to your account."),
            React.createElement("p", null, "The actual time it takes before you can see the data can vary, but smaller data sets should not take more than a couple of minutes to process, whereas larger data sets can potentially take hours before everything is ready for you."),
            React.createElement("p", null, "For reference 1000 time entries will take approximately 5 minutes to import.")));
    }
    else {
        return (React.createElement("div", null,
            React.createElement("p", null, "The import failed. Please reach out to our support for further assistance.")));
    }
};
const SettingsDataImporter = ({ viewer }) => {
    useTrackPage(PAGE_NAME);
    useEffect(() => {
        document.title = 'Data Importer - Account - Forecast';
        tracking.trackPage('settings-dataImporter');
    }, []);
    const [importMode, setImportMode] = useState(ImportMode.ImportNewProjects);
    const [nuvoImporter, setNuvoImporter] = useState(null);
    const [nuvoResult, setNuvoResult] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [migrationStatus, setMigrationStatus] = useState(null);
    const existingData = useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        return ({
            emails: (_d = (_c = (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.allPersons) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.map(e => { var _a; return (_a = e === null || e === void 0 ? void 0 : e.node) === null || _a === void 0 ? void 0 : _a.email; }).filter(Boolean)) !== null && _d !== void 0 ? _d : [],
            clients: (_h = (_g = (_f = (_e = viewer.company) === null || _e === void 0 ? void 0 : _e.clients) === null || _f === void 0 ? void 0 : _f.edges) === null || _g === void 0 ? void 0 : _g.map(e => { var _a; return (_a = e === null || e === void 0 ? void 0 : e.node) === null || _a === void 0 ? void 0 : _a.name; }).filter(Boolean)) !== null && _h !== void 0 ? _h : [],
            rateCards: (_m = (_l = (_k = (_j = viewer.company) === null || _j === void 0 ? void 0 : _j.rateCards) === null || _k === void 0 ? void 0 : _k.edges) === null || _l === void 0 ? void 0 : _l.map(e => { var _a; return (_a = e === null || e === void 0 ? void 0 : e.node) === null || _a === void 0 ? void 0 : _a.name; }).filter(Boolean)) !== null && _m !== void 0 ? _m : [],
            projects: (_q = (_p = (_o = viewer.company) === null || _o === void 0 ? void 0 : _o.allCompanyProjectIds) === null || _p === void 0 ? void 0 : _p.filter(Boolean).map((id) => '' + id)) !== null && _q !== void 0 ? _q : [],
            tasks: (_t = (_s = (_r = viewer.company) === null || _r === void 0 ? void 0 : _r.allCompanyTaskIds) === null || _s === void 0 ? void 0 : _s.filter(Boolean).map((id) => '' + id)) !== null && _t !== void 0 ? _t : [],
        });
    }, [viewer]);
    const confirmDataUpload = () => __awaiter(void 0, void 0, void 0, function* () {
        setSubmitLoading(true);
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        const data = {
            method: 'POST',
            headers,
            credentials: 'include',
            body: JSON.stringify(nuvoResult),
        };
        yield fetch(DirectApi.graphqlServerEndpoint(`data_migration_upload`), data).then(response => {
            setSubmitLoading(false);
            setMigrationStatus(response.ok);
            if (!response.ok) {
                Sentry.captureException(new Error('Data Importer Failed'), {
                    tags: { bugduty: true, team: Team.Team1 },
                });
            }
        });
    });
    const cancelDataUpload = () => __awaiter(void 0, void 0, void 0, function* () {
        setNuvoResult(null);
        window.location.reload();
    });
    const initializeDataImport = (mode) => {
        var _a, _b;
        const clientOptions = existingData.clients.map(client => {
            return {
                label: client,
                value: client,
                type: 'string',
            };
        });
        const rateCardOptions = existingData.rateCards.map(rateCard => {
            return {
                label: rateCard,
                value: rateCard,
                type: 'string',
            };
        });
        let settings;
        if (mode === ImportMode.ImportNewProjects) {
            settings = settingsForNewProjectsAndTasks(clientOptions, rateCardOptions);
        }
        else if (mode === ImportMode.ImportTimeRegs) {
            settings = settingsForExistingProjectsAndTasks();
        }
        else if (mode === ImportMode.ImportPhasesTasksTimeRegs) {
            settings = settingsForNewPhasesTasksAndTimeRegsOnExistingProjects();
        }
        let key = '';
        if (process.env.CIRCLE_BRANCH === 'production' && !((_a = viewer.email) === null || _a === void 0 ? void 0 : _a.includes('@forecast.'))) {
            key = '+8GEeJk1ivE5xGFO6UiXIXwBOCkqqusXpqsvW3ilc78=';
            settings.developerMode = false;
        }
        else {
            key = 'KunfteTHBK0NElFNkgtu3ap0g/m4SJz6qtbLHXME1U8=';
        }
        const nuvoImporter = new window.nuvo.NuvoImporter(key, settings);
        nuvoImporter.onResults((results, _, complete) => {
            // Reserve the second parameter for the upcoming 'error' parameter.
            setNuvoResult(results);
            complete();
        });
        nuvoImporter.onCancel(() => cancelDataUpload());
        const validator = new DataImportValidator({
            nuvoImporter,
            importMode: mode,
            projects: existingData.projects.map(id => 'P' + id),
            tasks: existingData.tasks.map(id => 'T' + id),
            emails: existingData.emails.map(e => e.toLowerCase()),
            companyIdBase64: (_b = viewer.company) === null || _b === void 0 ? void 0 : _b.id,
        });
        if (mode === ImportMode.ImportNewProjects) {
            validator.registerColumnHooksTaskEmails();
        }
        else if (mode === ImportMode.ImportTimeRegs) {
            validator.registerValidationForExistingTasksAndProjects();
        }
        else if (mode === ImportMode.ImportPhasesTasksTimeRegs) {
            validator.registerColumnHooksForExistingProjects();
            validator.registerColumnHooksTaskEmails();
        }
        validator.registerColumnHooksForTimeRegEmails();
        validator.registerEntryChange();
        try {
            nuvoImporter.launch();
        }
        catch (e) {
            // eslint-disable-next-line no-empty
        }
        const target = document.getElementById(nuvoImporter.getId());
        const c = document.getElementById('nuvo-container');
        c === null || c === void 0 ? void 0 : c.append(target);
        const f = document.getElementsByTagName('iframe');
        if (window.innerHeight < 600) {
            f[0].style.height = '60vh';
        }
        else if (window.innerHeight < 900) {
            f[0].style.height = '70vh';
        }
        else {
            f[0].style.height = '80vh';
        }
        setNuvoImporter(nuvoImporter);
    };
    useEffect(() => {
        if (nuvoImporter === null) {
            loadScript('https://unpkg.com/nuvo-vanilla-js@2.8.0', () => initializeDataImport(importMode));
        }
        else {
            const target = document.getElementById(nuvoImporter.getId());
            target === null || target === void 0 ? void 0 : target.remove();
            initializeDataImport(importMode);
        }
    }, [importMode]);
    return (React.createElement("div", null,
        React.createElement(DescriptionStyled, null,
            React.createElement(TopHeader, null),
            React.createElement("div", { className: "description" },
                React.createElement(ImportSelection, { importMode: importMode, setImportMode: setImportMode, disabled: nuvoResult !== null })),
            nuvoResult === null ? (React.createElement("div", { className: "my-nuvo-style", id: "nuvo-container" })) : migrationStatus === null ? (React.createElement(SubmitDataImport, { importMode: importMode, newData: nuvoResult, existingData: existingData, onSubmit: confirmDataUpload, onCancel: cancelDataUpload, loading: submitLoading })) : (React.createElement("div", { className: "sub-description" },
                React.createElement(ResultText, { success: migrationStatus }))))));
};
const settingsDataImporterQuery = graphql `
	query SettingsDataImporter_Query {
		viewer {
			actualPersonId
			component(name: "data_importer")
			...SettingsDataImporter_viewer
		}
	}
`;
export { settingsDataImporterQuery };
export default createFragmentContainer(SettingsDataImporter, {
    viewer: graphql `
		fragment SettingsDataImporter_viewer on Viewer {
			email
			company {
				id
				allCompanyTaskIds
				allCompanyProjectIds
				allPersons(first: 10000) {
					edges {
						node {
							id
							email
						}
					}
				}
				clients(first: 10000) {
					edges {
						node {
							id
							name
						}
					}
				}
				rateCards(first: 10000) {
					edges {
						node {
							id
							name
						}
					}
				}
				roles(first: 10000) {
					edges {
						node {
							id
							name
						}
					}
				}
			}
		}
	`,
});
