/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type settingsIdleTimes_viewer$ref = any;
export type settingsIdleTimes_QueryVariables = {||};
export type settingsIdleTimes_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: settingsIdleTimes_viewer$ref,
  |}
|};
export type settingsIdleTimes_Query = {|
  variables: settingsIdleTimes_QueryVariables,
  response: settingsIdleTimes_QueryResponse,
|};
*/


/*
query settingsIdleTimes_Query {
  viewer {
    actualPersonId
    component(name: "settings_idle_times")
    ...settingsIdleTimes_viewer
    id
  }
}

fragment TimeIncrementsSection_company on Company {
  timeRegistrationSettings {
    id
    granularity
  }
}

fragment TimeRegistrationSettingsSection_company on Company {
  timeRegistrationSettings {
    id
    allowDoneTasks
    allowProjects
    allowInternalTime
    allowBillableSplit
    allowRoles
    allowExceedingEstimate
    allowOutsideProjectDates
    allowOutsideTaskDates
    requireTaskAssignment
    requireNote
    strictMode
  }
}

fragment settingsIdleTimes_viewer on Viewer {
  id
  company {
    id
    useTimeApproval
    useInternalTimeApproval
    useTimeOffApproval
    allUsersModifyTimeOff
    useTimeLocking
    lockedPeriodYear
    lockedPeriodMonth
    lockedPeriodDay
    harvestEnabled
    jiraCloudEnabled
    jiraServerEnabled
    unit4Enabled
    bambooHREnabled
    ...TimeRegistrationSettingsSection_company
    ...TimeIncrementsSection_company
    allocations(first: 100000, idleTimeOnly: true) {
      edges {
        node {
          id
          idleTime {
            id
          }
        }
      }
    }
    idleTimes(first: 1000) {
      edges {
        node {
          id
          name
          isInternalTime
          disabled
          timeRegistrations(first: 1) {
            edges {
              node {
                id
              }
            }
          }
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "settings_idle_times"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"settings_idle_times\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingsIdleTimes_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "settingsIdleTimes_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingsIdleTimes_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "useTimeApproval",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "useInternalTimeApproval",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "useTimeOffApproval",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allUsersModifyTimeOff",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "useTimeLocking",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lockedPeriodYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lockedPeriodMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lockedPeriodDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "harvestEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jiraCloudEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jiraServerEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unit4Enabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bambooHREnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TimeRegistrationSettingsType",
                "kind": "LinkedField",
                "name": "timeRegistrationSettings",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowDoneTasks",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowProjects",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowInternalTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowBillableSplit",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowRoles",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowExceedingEstimate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowOutsideProjectDates",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowOutsideTaskDates",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "requireTaskAssignment",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "requireNote",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "strictMode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "granularity",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 100000
                  },
                  {
                    "kind": "Literal",
                    "name": "idleTimeOnly",
                    "value": true
                  }
                ],
                "concreteType": "AllocationTypeConnection",
                "kind": "LinkedField",
                "name": "allocations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AllocationTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Allocation",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "IdleTime",
                            "kind": "LinkedField",
                            "name": "idleTime",
                            "plural": false,
                            "selections": (v3/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "allocations(first:100000,idleTimeOnly:true)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "IdleTimeTypeConnection",
                "kind": "LinkedField",
                "name": "idleTimes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "IdleTimeTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "IdleTime",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isInternalTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "disabled",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 1
                              }
                            ],
                            "concreteType": "TimeRegTypeConnection",
                            "kind": "LinkedField",
                            "name": "timeRegistrations",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TimeRegTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TimeRegType",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": (v3/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "timeRegistrations(first:1)"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "idleTimes(first:1000)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "Company_idleTimes",
                "kind": "LinkedHandle",
                "name": "idleTimes"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "settingsIdleTimes_Query",
    "operationKind": "query",
    "text": "query settingsIdleTimes_Query {\n  viewer {\n    actualPersonId\n    component(name: \"settings_idle_times\")\n    ...settingsIdleTimes_viewer\n    id\n  }\n}\n\nfragment TimeIncrementsSection_company on Company {\n  timeRegistrationSettings {\n    id\n    granularity\n  }\n}\n\nfragment TimeRegistrationSettingsSection_company on Company {\n  timeRegistrationSettings {\n    id\n    allowDoneTasks\n    allowProjects\n    allowInternalTime\n    allowBillableSplit\n    allowRoles\n    allowExceedingEstimate\n    allowOutsideProjectDates\n    allowOutsideTaskDates\n    requireTaskAssignment\n    requireNote\n    strictMode\n  }\n}\n\nfragment settingsIdleTimes_viewer on Viewer {\n  id\n  company {\n    id\n    useTimeApproval\n    useInternalTimeApproval\n    useTimeOffApproval\n    allUsersModifyTimeOff\n    useTimeLocking\n    lockedPeriodYear\n    lockedPeriodMonth\n    lockedPeriodDay\n    harvestEnabled\n    jiraCloudEnabled\n    jiraServerEnabled\n    unit4Enabled\n    bambooHREnabled\n    ...TimeRegistrationSettingsSection_company\n    ...TimeIncrementsSection_company\n    allocations(first: 100000, idleTimeOnly: true) {\n      edges {\n        node {\n          id\n          idleTime {\n            id\n          }\n        }\n      }\n    }\n    idleTimes(first: 1000) {\n      edges {\n        node {\n          id\n          name\n          isInternalTime\n          disabled\n          timeRegistrations(first: 1) {\n            edges {\n              node {\n                id\n              }\n            }\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '579b8940f41af6cbbd82210576802826';

module.exports = node;
